<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :color="'#003A63'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    @mouseleave.native="drawerHover = false"
    @mouseover.native="drawerHover = true"
    :mobile-break-point="960"
    app
    mini-variant-width="80"
    width="300"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <div style="position: relative">
      <v-list-item two-line>
        <v-list-item-content
          align="center"
          justify="center"
        >
          <router-link to="/home">
            <v-img
              v-if="drawerExpanded || (drawerHover && !drawerExpanded)"
              width="144"
              height="60"
              src="@/assets/logo-branco.png"
            />
            <v-img
              v-else
              width="48"
              height="20"
              src="@/assets/logo-branco.png"
            />
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-1" />

      <v-list
        dense
        nav
        id="drawer-section-profile"
      >
        <base-item-group
          v-if="profile"
          :item="profile"
          class="drawerItemList mr-1"
          :recolherSubMenus="!drawerHover ? false : true"
        />
      </v-list>

      <v-divider class="mb-2" />

      <div @click.stop="openDialogChangeCompany()">
        <v-list
          dense
          nav
        >
          <base-item
            :href="undefined"
            :item="selectedCompany"
            class="mr-1"
          />
        </v-list>
      </div>

      <v-divider class="mb-2" />

      <v-list
        expand
        nav
      >
        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />

        <template v-for="(item, i) in computedItems">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
            :idElement="
              'drawer-section-' + item.title.toLowerCase().replace(/ /g, '')
            "
            class="mr-1"
          />
        </template>

        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
      </v-list>

      <v-divider class="mb-2" />

      <v-list
        expand
        nav
      >
        <v-list-item
          id="drawer-section-tutorial"
          :href="undefined"
          @click="forceInitTourCurrentPage()"
          class="mr-1"
        >
          <v-list-item-icon class="v-list-item__icon--text">
            <v-icon> mdi-help-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Tutorial </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider
        class="mb-1"
        style="margin-top: 10px"
      />
    </div>

    <v-dialog
      v-model="dialogChangeCompany"
      max-width="340px"
      eager
    >
      <v-card>
        <v-card-title class="headline">Mudar Empresa</v-card-title>
        <v-card-text>
          <v-row class="mx-auto">
            <select-company
              ref="refSelectCompany"
              style="min-width: 100%"
              :changeCompany="false"
              @selected="handleCompanySelected"
              @companySelected="changeSelectedCompany($event)"
            />
          </v-row>
          <v-row class="mx-auto">
            <select-all-bdgd-versions
              ref="refSelectBdgdVersionId"
              style="min-width: 100%"
              :showIcon="false"
              :companyId="newSelectedCompanyId"
              @bdgdVersionSelected="handleBdgdVersionSelected"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="triggerSelectCompanyEvent()"
          >
            Confirmar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click.stop="dialogChangeCompany = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import AuthService from '@/services/AuthService';
import MonitoramentoEmTempoRealService from '@/services/monitoramento-em-tempo-real/MonitoramentoEmTempoRealService';
import SelectCompany from '@/components/general/SelectCompany';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [profilePermissionsMixin],
  name: 'DashboardCoreDrawer',
  components: {
    SelectCompany,
    SelectAllBdgdVersions
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    },
    drawerExpanded: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.getProfile();
    this.getUserIsAdmin();
    this.getMenuMonitoramentoHabilitado();
  },

  data: () => ({
    drawerHover: false,
    profilePic: null,
    profile: null,
    userIsAdmin: false,
    menuMonitoramentoHabilitado: false,
    itemTour: {
      icon: 'mdi-help-circle',
      title: 'Tutorial'
    },
    dialogChangeCompany: false,
    newSelectedCompanyId: null,
    newSelectedBdgdVersion: null
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),

    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val);
      }
    },

    computedItems() {
      let items = [];

      items.push({
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/home'
      });

      if (
        this.accessReleased('MAPAS') &&
        (this.accessReleased('LAYERS_PERSONALIZADOS_HISTORICO') ||
          this.accessReleased('CANVAS_HISTORICO') ||
          this.accessReleased(
            'ENTIDADES_GEOGRAFICAS_DA_BDGD_VISUALIZAR_MAPA'
          ) ||
          this.accessReleased('SETORIZACOES_HISTORICO') ||
          this.accessReleased('ANALISES_SETORIZADAS_HISTORICO'))
      ) {
        items.push({
          icon: 'mdi-map-search-outline',
          title: 'Criação de Mapas',
          to: '/mapas'
        });
      }

      if (
        this.menuMonitoramentoHabilitado &&
        this.accessReleased('MONITORAMENTO_EM_TEMPO_REAL') &&
        this.accessReleased('BEACONS_X_EQUIPAMENTOS_LISTAR')
      ) {
        items.push({
          icon: 'mdi-map-marker-radius',
          title: 'Monitoramento em Tempo Real',
          to: '/monitoramento-em-tempo-real'
        });
      }

      if (
        this.accessReleased('POWER_UPS') &&
        (this.accessReleased('SIGR_COMPARA_HISTORICO') ||
          this.accessReleased('CONCILIACOES_HISTORICO'))
      ) {
        items.push({
          icon: 'mdi-lightning-bolt',
          title: 'Power Ups',
          to: '/powerups'
        });
      }

      if (
        this.accessReleased('GERACAO_DA_BDGD') &&
        (this.accessReleased('EXTRAIR_BDGD_HISTORICO') ||
          this.accessReleased('IMPORTACOES_DE_BDGD') ||
          this.accessReleased('RELATORIOS_HISTORICO') ||
          this.accessReleased('VALIDACOES_DA_BDGD_HISTORICO') ||
          this.accessReleased('VALIDACOES_INDQUAL') ||
          this.accessReleased('VALIDACOES_SAMP') ||
          this.accessReleased('ATUALIZACOES_DE_BDGD') ||
          this.accessReleased('CONECTIVIDADE_E_PERDAS_TECNICAS') ||
          this.accessReleased('EXPORTAR_BDGD_HISTORICO'))
      ) {
        items.push({
          icon: 'mdi-database-plus',
          title: 'Geração da BDGD',
          to: '/geracao-bdgd'
        });
      }

      if (
        this.accessReleased('CONECTIVIDADE_E_PERDAS_TECNICAS') &&
        (this.accessReleased('CARREGAR_BDGD_HISTORICO') ||
          this.accessReleased('PREENCHER_TABELAS_OPERACIONAIS_HISTORICO') ||
          this.accessReleased('SEQUENCIAMENTO_ELETRICO') ||
          this.accessReleased('MALHA') ||
          this.accessReleased('FASEAMENTO') ||
          this.accessReleased('PROG_GEOPERDAS_ANEEL') ||
          this.accessReleased('EXPORTAR_BDGD_HISTORICO'))
      ) {
        items.push({
          icon: 'mdi-transmission-tower-export',
          title: 'Perdas Técnicas',
          to: '/perdas-tecnicas'
        });
      }

      if (this.accessReleased('RELATORIOS_HISTORICO')) {
        items.push({
          icon: 'mdi-clipboard-outline',
          title: 'Relatórios',
          to: '/relatorios/historico'
        });
      }

      if (
        this.accessReleased('DADOS') &&
        (
          this.accessReleased('ARQUIVOS_DE_DADOS') ||
          this.accessReleased('IMPORTACOES_DE_DADOS') ||
          this.accessReleased('CARGAS_CSV') ||
          this.accessReleased('IMPORTACOES_INDQUAL') ||
          this.accessReleased('VERSOES_DA_BDGD_HISTORICO') ||
          this.accessReleased('EXPURGOS_DE_BDGD_HISTORICO') ||
          this.accessReleased('RESTAURAR_BDGD_HISTORICO')
        )
      ) {
        items.push({
          icon: 'mdi-database',
          title: 'Dados',
          to: '/dados'
        });
      }

      if (
        this.accessReleased('ADMINISTRACAO') &&
        (this.accessReleased('USUARIOS_LISTAR') ||
          this.accessReleased('PERFIS_USUARIOS_LISTAR') ||
          this.accessReleased('LOGS_ACESSOS_USUARIOS_LISTAR') ||
          this.accessReleased('EMPRESAS_LISTAR') ||
          this.accessReleased('GEOPERDAS_SERVIDORES_BANCOS_DADOS_LISTAR') ||
          this.accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_LISTAR') ||
          this.accessReleased('SERVIDORES_EC2_NA_AWS_LISTAR') ||
          this.accessReleased('SERVIDORES_RDS_NA_AWS_LISTAR') ||
          this.accessReleased('RELATORIOS_MODELOS_HISTORICO') ||
          this.accessReleased('TABELAS_X_DISCO'))
      ) {
        items.push({
          icon: 'mdi-cog',
          title: 'Administração',
          to: '/admin'
        });
      }

      return items.map(this.mapItem);
    },

    getCssLogoPositionLogo() {
      let heightDrawer = document.getElementById('core-navigation-drawer');
      heightDrawer = heightDrawer ? heightDrawer.offsetHeight : 645;
      return heightDrawer <= 644 ? 'relative' : 'fixed';
    },

    selectedCompany() {
      return {
        sigla: this.$store.getters.getCompanyAcronym,
        title: this.$store.getters.getCompanyName,
        id: this.$store.getters.getCompanyId
      };
    }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', !val);
    }
  },

  methods: {
    async getProfile() {
      this.profile = {
        avatar: true,
        img: false,
        group: '/perfil',
        title: this.$store.getters.getUserName,
        children: [
          {
            icon: 'mdi-account',
            to: 'index',
            title: 'Perfil'
          },
          {
            icon: 'mdi-logout',
            to: 'logout',
            title: 'Logout'
          }
        ]
      };
    },

    getUserIsAdmin() {
      AuthService.userIsAdmin().then(
        (userIsAdmin) => (this.userIsAdmin = userIsAdmin)
      );
    },

    getMenuMonitoramentoHabilitado() {
      MonitoramentoEmTempoRealService.getEstaHabilitado().then((habilitado) => {
        this.menuMonitoramentoHabilitado = Boolean(habilitado);
      });
    },

    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    },

    forceInitTourCurrentPage() {
      let arrTourInfo = this.$store.getters.getTourInfo;
      let nomePagina = this.$router.history.current.name;

      arrTourInfo.forEach((tourInfo) => {
        if (tourInfo.nome_pagina == nomePagina)
          tourInfo.tour_completado = false;
      });

      this.$store.dispatch('setTour', arrTourInfo);
      location.reload();
    },

    openDialogChangeCompany() {
      this.dialogChangeCompany = true;
      this.$refs.refSelectCompany.company = this.selectedCompany.id;
      this.newSelectedCompanyId = this.selectedCompany.id;
    },

    handleCompanySelected(companyId) {
      this.newSelectedCompanyId = companyId;
    },

    handleBdgdVersionSelected(bdgdVersion) {
      this.newSelectedBdgdVersion = bdgdVersion;
    },

    triggerSelectCompanyEvent() {
      this.$refs.refSelectCompany.companySelected(
        this.$refs.refSelectCompany.company
      );
    },

    async changeSelectedCompany(company) {
      this.dialogChangeCompany = false;
      await this.$store.dispatch('setSelectedCompany', company);
      await this.$store.dispatch('setUserIsAdmin');

      if (this.newSelectedBdgdVersion) {
        this.$store.dispatch(
          'setUserSelectedCompanyBdgdVersionId',
          this.newSelectedBdgdVersion.id
        );
      }
      setTimeout(() => {
        location.reload();
      }, 100);
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
